import React, { useEffect, Fragment} from "react";
import { 
  Typography, List, ListItem, ListItemIcon, Divider, Hidden
} from '@material-ui/core';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { navigate } from 'hookrouter';

const GroupSidebar = (props) => {

  //  const classes = useStyle();

  const {
    sidebarOpenMenuType,
    listItemMenu,
  } = props;

  useEffect(() => {
 
      
  },[])

  const handleSelectedSidebarMenu = (evt, item) => {
    console.log("navigate to ", item.navigate);
    navigate(item.navigate);
  }

  /**
   * Handle click daftar perusahaan
   */
  const handleClickGroupList = (evt) => {
    console.log("navigate to group list");
    navigate('/dashboard');
  }

  return (
    <div style={{ backgroundColor: '#edeef0', fontSize: '12pt', paddingLeft: '10px' }}>
      <List component="nav">
        <ListItem button
          onClick={ (evt) => {handleClickGroupList(evt)} }
        >
          <ListItemIcon>
            <KeyboardBackspaceIcon
            />&nbsp;&nbsp;Daftar Perusahaan
          </ListItemIcon>
        </ListItem>
      </List>
      <Divider />
      <List component="nav">
        {
          listItemMenu.length > 0 ? listItemMenu.map((item, i) => (
            <Fragment>
            {
              sidebarOpenMenuType === item.id ? (
                <ListItem button
                  onClick={ (evt) => { handleSelectedSidebarMenu(evt, item) } }
                  style={{ borderLeft: '4px solid DarkBlue' }}
                >
                  <Typography variant="inherit">&nbsp;&nbsp;&nbsp;&nbsp;<b>{ item.label }</b></Typography>
                </ListItem>
              ): (
                <ListItem button
                  onClick={ (evt) => { handleSelectedSidebarMenu(evt, item) } }
                >
                  <Typography variant="inherit">{ item.label }</Typography>
                </ListItem>
              )
            }
           </Fragment>
        
          )) : null
        }
        <Hidden smDown>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
     
        </Hidden>
      </List>
      <Divider />
    </div>
  )
};

export default GroupSidebar;
