import React, { useEffect, Fragment } from "react";
import {

  Container, Paper, Table, TableBody, TableRow, TableCell, TableContainer, TableHead,
  TablePagination, FormControl, Select, Grid, TextField, Dialog, DialogTitle, Typography, DialogActions,
  DialogContent, Button, Menu, MenuItem, CircularProgress
} from '@material-ui/core';

import { useDebouncedCallback } from "use-debounce";

import WarningIcon from '@material-ui/icons/Warning';

import isAuthenticated from '../../../utilities/IsAuthenticated';
import { navigate } from 'hookrouter';

import axiosConfig from '../../../constants/config-axios';
import CSVReader from "react-csv-reader";

import DialogError from "../../../components/DialogError";
import { URL_API_BACKEND } from '../../../constants/config-api';
import DateTimeFormatter from '../../../utilities/DateTimeFormatter';
import './Style/Bar.css';

const ViewNewDashboard = () => {
  /*
      ```````````````````
      COMPONENT DID MOUNT

      ```````````````````
  */

  /**
   * Handle GroupListCollections
   */
  const [groupListCollections, setGroupListCollections] = React.useState([]);

  /**
   * Filter membership status
   */
  const [filterMembershipStatus, setFilterMembershipStatus] = React.useState('-1');
  const handleSelectMembershipStatusFilter = (evt) => {
    resetPagingPage();
    // show throbber
    setIsGroupTableThrobberShown(true);
    setFilterMembershipStatus(evt.target.value);
  };

  /**
   * Filter search
   */
  const [ filterSearch, setFilterSearch ] = React.useState('');
  const [ handleFilterSearch ] = useDebouncedCallback(
      // to memoize debouncedFunction we use useCallback hook.
      // In this case all linters work correctly
      React.useCallback(value => {
        if (value) {
          resetPagingPage();
          setFilterSearch(value);
          // show throbber
          setIsGroupTableThrobberShown(true);
        } else {
          resetPagingPage();
          setFilterSearch('');
          // show throbber
          setIsGroupTableThrobberShown(true);
        }
      }, []), 1000,

      // The maximum time func is allowed to be delayed before it's invoked:
      { maxWait: 5000 }
  );


  /**
   * Membership status list collections
   */
  const [membershipStatusListCollections ] = React.useState([
    {
      'id': '-1',
      'name': 'Semua Status',
    },
    {
      'id': '1',
      'name': 'None'
    },
    {
      'id': '2',
      'name': 'Trial',
    },
    {
      'id': '3',
      'name': 'Active',
    },
    {
      'id': '20',
      'name': 'Lapsed',
    },
    {
      'id': '21',
      'name': 'Pause',
    },
    {
      'id': '22',
      'name': 'Inactive'
    }
  ]);

  /**
   * Paging
   */
  const [groupListCollectionsRowPerPageOptions] = React.useState([ 5, 10, 25, 50 ]);
  const [groupListCollectionsRowPerPage, setGroupListCollectionsRowPerPage] = React.useState(5);
  const [groupListCollectionsPage, setGroupListCollectionsPage] = React.useState(0);
  const [groupListCollectionsOriginalCount, setGroupListCollectionsOriginalCount] = React.useState(0);

  const handleGroupListCollectionsChangePage = (evt, newPage) => {
    // show throbber
    setIsGroupTableThrobberShown(true);
    setGroupListCollectionsPage(newPage);
  };

  const handleGroupListCollectionsChangeRowsPerPage = (evt) => {
    // show throbber
    setIsGroupTableThrobberShown(true);
    setGroupListCollectionsRowPerPage(parseInt(evt.target.value, 10));
    setGroupListCollectionsPage(0);
  };

  const resetPagingPage = () => {
    setGroupListCollectionsPage(0);
  };

  /**
   *  Handle throbber
   */
  const [isGroupTableThrobberShown, setIsGroupTableThrobberShown] = React.useState(true);

  /**
   * Handle request groupListCollections
   */
  const requestGroupListCollections = () => {

    console.log("group list collection throbber", isGroupTableThrobberShown);
    // get credential token
    const userToken = localStorage.getItem('userToken');

    if (userToken !== undefined) {
      // sanitize filter
      let queryFilterMembershipStatus = filterMembershipStatus;
      if (queryFilterMembershipStatus === '-1') {
        queryFilterMembershipStatus = '';
      }
      let queryFilterSearch = filterSearch;

      // sanitize paging
      let queryGroupListCollectionsRowPerPage = groupListCollectionsRowPerPage;
      let queryGroupListCollectionPagingOffset = groupListCollectionsPage * groupListCollectionsRowPerPage;

      let requestUrl = `${URL_API_BACKEND}/account-management/group?options[filter][membership_status_id]=${queryFilterMembershipStatus}&options[filter][search]=${queryFilterSearch}&options[paging][limit]=${queryGroupListCollectionsRowPerPage}&options[paging][offset]=${queryGroupListCollectionPagingOffset}`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .get(requestUrl)
          .then((response) => {

            console.log("Response Original GROUP LIST COLLECTIONS : ", response)
            if (response.data.data !== null) {
              setGroupListCollections(response.data.data)
              setGroupListCollectionsOriginalCount(response.data.info.count);
            } else {
              setGroupListCollectionsOriginalCount(0);

            }
          })
          .catch((error) => { // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }


            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          })


      setIsGroupTableThrobberShown(false);
      console.log("group list collection throbber", isGroupTableThrobberShown);
    }
  };

  /**
   * Handle error dialog
   */
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = React.useState('whoops something went wrong');
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);

  useEffect(() => {
    // checking is authenticated
    isAuthenticated();
    // request group list collections
    requestGroupListCollections();

  },[filterMembershipStatus, filterSearch, groupListCollectionsPage, groupListCollectionsRowPerPage])

  /**
   * Action button state
   */
  const [actionButtonItem, setActionButtonItem] = React.useState(null);
  const [actionButtonAnchorEl, setActionButtonAnchorEl] = React.useState(null);

  /**
   * Handle click action button
   */
  const handleClickActionButton = (evt, item) => {
    setActionButtonItem(item);
    setActionButtonAnchorEl(evt.currentTarget);
  };

  /**
   * Handle close action button
   */
  const handleCloseActionButton = () => {
    setActionButtonItem(null);
    setActionButtonAnchorEl(null);
  };

  /**
   * Handle click edit button
   */
  const handleClickEditButton = (evt, item) => {
    navigate('/account-management/group/' + item.id);
  };

  /**
   * Handle Open Delete Form
   *
   * @param {object} evt Event object
   * @param {object} item Group object
   */
  const [isDeleteFormThrobberShown, setIsDeleteFormThrobberShown] = React.useState(false);
  const [isDeleteFormShown, setIsDeleteFormShown] = React.useState(false);
  const [deleteFormMessageContent, setDeleteFormMessageContent] = React.useState('');
  const [deleteFormTitleContent, setDeleteFormTitleContent] = React.useState('');
  const [deleteButtonTimer, setDeleteButtonTimer] = React.useState(0);
  const [deleteButtonTimerActive, setDeleteButtonTimerActive] = React.useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = React.useState(null);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = React.useState(true);
  const [isCancelButtonEnabled, setIsCancelButtonEnabled] = React.useState(true);

  // handle timer
  useEffect(() => {

    let interval = null;
    if (deleteButtonTimerActive && deleteButtonTimer > 0) {
      interval = setInterval(() => {
        setDeleteButtonTimer(deleteButtonTimer => deleteButtonTimer - 1);
      }, 1000);
    } else if (deleteButtonTimerActive && deleteButtonTimer <= 0) {
      setDeleteButtonTimerActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);

  }, [deleteButtonTimerActive, deleteButtonTimer]);

  const handleOpenDeleteForm = (evt, item) => {
    // define shown and throbber
    setIsDeleteFormShown(true);
    setIsDeleteFormThrobberShown(true);
    setIsDeleteButtonEnabled(true);
    setIsCancelButtonEnabled(true);

    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = item.id;

    if (userToken !== undefined && id !== undefined) {
      // define title
      let groupName = item.name !== '' && item.name !== null ? (item.name.toUpperCase()) : ('-');
      let superadminEmail = item.superadmin_email !== '' && item.superadmin_email !== null ? (item.superadmin_email) : ('-');
      setDeleteFormTitleContent(`Hapus Akun ${groupName} ${superadminEmail}`);

      // define request url
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}/delete`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .get(requestUrl)
          .then((response) => {
            setIsDeleteFormThrobberShown(false);
            setDeleteButtonTimer(15);
            setDeleteButtonTimerActive(true);
            setSelectedDeleteItem(item);
            setDeleteFormMessageContent(`Akun ${groupName} dalam masa ${item.membershipStatus !== null && typeof item.membershipStatus !== undefined ? (item.membershipStatus.name) : ('-') }. Apakah anda yakin ingin menghapus akun ini, langkah ini tidak bisa dibatalkan !.`);
          })
          .catch((error) => {
            // cleanse the content
            console.log("errors:", error);
            setDeleteButtonTimer(0);
            setDeleteButtonTimerActive(false);
            setIsDeleteFormThrobberShown(false);
            setIsDeleteFormShown(false);
            setDeleteFormMessageContent('');
            setDeleteFormTitleContent('');
            setSelectedDeleteItem(null);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  };

  /**
   * Handle close delete form
   *
   * @param {*} evt
   *
   */
  const handleCloseDeleteForm = (evt) => {
    setDeleteButtonTimer(0);
    setDeleteButtonTimerActive(false);
    setIsDeleteFormThrobberShown(false);
    setIsDeleteFormShown(false);
    setDeleteFormMessageContent('');
    setDeleteFormTitleContent('');
    setSelectedDeleteItem(null);
    setIsDeleteButtonEnabled(true);
    setIsCancelButtonEnabled(true);
  }

  /**
   * Handle export button
   */
  const handleExportAllButton = () => {

    let userToken = localStorage.getItem('userToken');
    let requestUrl = `${URL_API_BACKEND}/account-management/group:export?key=${userToken}`;

    window.open( requestUrl, '_blank');
  }

  /**
   * Handle report Goal Measured Activity
   */
  const handleReportGoalMeasuredActivity = (item) => {

    let userToken = localStorage.getItem('userToken');
    let requestUrl = `${URL_API_BACKEND}/report/goal-measured-activity:export?key=${userToken}&options[filter][group_id]=${item.id}`;

    window.open( requestUrl, '_blank');
  }

  /**
   * Handle click delete button
   */
  const [deletePercentages, setDeletePercentages] = React.useState(0.00);
  const handleClickDeleteButton = (evt) => {

    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = selectedDeleteItem.id;

    // checking user credential
    if (userToken !== undefined && id !== undefined) {

      // disabling
      setIsDeleteButtonEnabled(false);
      setIsCancelButtonEnabled(false);

      // define delete interval
      let deleteActionInterval = null;
      deleteActionInterval = setInterval(() => {
        if (deletePercentages <= 99.95) {
          setDeletePercentages(deletePercentages => deletePercentages + 0.05);
        }
      }, 100);

      // define request url
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}`;

      // define request body
      let requestBody = {
        '_method': 'delete'
      };
      console.log("requesting from:", requestUrl);
      console.log("request body:", requestBody);

      axiosConfig
          .post(requestUrl, requestBody)
          .then((response) => {
            // clear interval
            clearInterval(deleteActionInterval);
            // set percentages to 100
            setDeletePercentages(100.00);
            // set timeout 3 second before close form
            setTimeout(() => {
              // remove from collections
              if (groupListCollections.length > 0) {
                setGroupListCollections( groupListCollections.filter(row => row.id !== id) );
              }
              setDeleteButtonTimer(0);
              setDeletePercentages(0.00);
              setDeleteButtonTimerActive(false);
              setIsDeleteFormThrobberShown(false);
              setIsDeleteFormShown(false);
              setDeleteFormMessageContent('');
              setDeleteFormTitleContent('');
              setSelectedDeleteItem(null);
              setIsDeleteButtonEnabled(true);
              setIsCancelButtonEnabled(true);
            }, 3000);
          })
          .catch((error) => {
            // cleanse form
            clearInterval(deleteActionInterval);
            setDeletePercentages(0.00);
            setIsDeleteButtonEnabled(true);
            setIsCancelButtonEnabled(true);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  };

  /**
   * Define import goal and ma states
   */
  const [isImportGoalMaFormThrobberShown, setIsImportGoalMaFormThrobberShown] = React.useState(false);
  const [isImportGoalMaFormShown, setIsImportGoalMaFormShown] = React.useState(false);
  const [isImportGoalMaSubmitButtonEnabled, setIsImportGoalMaSubmitButtonEnabled] = React.useState(false);
  const [isCancelImportGoalMaButtonEnabled, setIsCancelImportGoalMaButtonEnabled] = React.useState(true);
  const [groupImportGoalMa, setGroupImportGoalMa] = React.useState({});
  const [importGoalMaData, setImportGoalMaData] = React.useState([]);
  const [importGoalMaError, setImportGoalMaError] = React.useState([]);

  const preprocessImportData = (data) => {

    // preprocess it
    let preprocessedImport = [];
    let length = data.length;
    for (let i = 0; i < length; i++) {
      // strip all row with null values
      if (!data[i]['id'] && !data[i]['goal_id'] && !data[i]['category'] && !data[i]['name'] && !data[i]['owner_email'] && !data[i]['start_date'] && !data[i]['end_date'] && !data[i]['period'] && !data[i]['description'] && !data[i]['start_value'] && !data[i]['target_value'] && !data[i]['target_range_value'] && !data[i]['input_method'] && !data[i]['calculation_method'] && !data[i]['achievement_type'] && !data[i]['target_type'] && !data[i]['value_type'] )
      {
        // do nothing
      }
      else
      {
        data[i]['group_id'] = groupImportGoalMa.id;
        preprocessedImport.push(data[i]);
      }
    }

    return preprocessedImport;
  }

  /**
   * Handle Upload Import Goal Ma
   */
  const handleUploadImportGoalMa = (data, fileInfo) => {
    console.log('original data imported: ', data);

    // cleanse
    setImportGoalMaError([]);
    setIsImportGoalMaSubmitButtonEnabled(false);

    let preprocessedImport = preprocessImportData(data);
    let preprocessedImportLength = preprocessedImport.length;

    if (preprocessedImportLength > 0) {
      setImportGoalMaData(preprocessedImport);

      // get user token
      const userToken = localStorage.getItem('userToken');

      // define request url
      let requestUrl = `${URL_API_BACKEND}/swd/goal-measured-activity:import?validateOnly=true`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .post(requestUrl, {
            "GoalMeasuredActivity": preprocessedImport
          })
          .then((response) => {
            // berhasil hore
            setIsImportGoalMaSubmitButtonEnabled(true);
          })
          .catch((error) => {
            // cleanse the content
            console.log("errors:", error);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;
              setImportGoalMaError(error.response.data.info.errors);
              if ([ 503, 500, 403, 429, 405, 404, 401 ].includes(responseStatus) && error.response.data.hasOwnProperty("message")) {
                setErrorStatus(responseStatus);
                setOpenDialogError(true);
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  }

  /**
   * Handle submit upload import goal ma
   */
  const handleSubmitImportGoalMa = () => {
    setIsImportGoalMaSubmitButtonEnabled(false);
    setIsImportGoalMaFormThrobberShown(true);


    // cleanse
    setImportGoalMaError([]);
    setIsImportGoalMaSubmitButtonEnabled(false);
    let preprocessedImportLength = importGoalMaData.length;

    if (preprocessedImportLength > 0) {
      // get user token
      const userToken = localStorage.getItem('userToken');

      // define request url
      let requestUrl = `${URL_API_BACKEND}/swd/goal-measured-activity:import`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .post(requestUrl, {
            "GoalMeasuredActivity": importGoalMaData
          })
          .then((response) => {
            // berhasil hore
            alert('data telah berhasil di import');
            handleCloseImportGoalMaForm();
          })
          .catch((error) => {
            // cleanse the content
            console.log("errors:", error);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);
              setIsImportGoalMaSubmitButtonEnabled(true);
              setIsImportGoalMaFormThrobberShown(false);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  }

  /**
   * Handle open import form
   */
  const handleOpenImportGoalMaForm = (item) => {
    setIsImportGoalMaFormShown(true);
    setIsImportGoalMaSubmitButtonEnabled(false);
    setIsImportGoalMaFormThrobberShown(false);
    setGroupImportGoalMa(item);
  }

  /**
   * Handle close import goal ma form
   */
  const handleCloseImportGoalMaForm = () => {
    setIsImportGoalMaFormShown(false);
    setIsImportGoalMaSubmitButtonEnabled(false);
    setIsImportGoalMaFormThrobberShown(false);
    setGroupImportGoalMa({});
    setImportGoalMaData([]);
  }

  /**
   * Define import MA states
   */
  const [isImportMaFormThrobberShown, setIsImportMaFormThrobberShown] = React.useState(false);
  const [isImportMaFormShown, setIsImportMaFormShown] = React.useState(false);
  const [isImportMaSubmitButtonEnabled, setIsImportMaSubmitButtonEnabled] = React.useState(false);
  const [isCancelImportMaButtonEnabled, setIsCancelImportMaButtonEnabled] = React.useState(true);
  const [groupImportMa, setGroupImportMa] = React.useState({});
  const [importMaData, setImportMaData] = React.useState([]);
  const [importMaError, setImportMaError] = React.useState([]);
  const preprocessImportMaData = (data) => {

    // preprocess it
    let preprocessedImport = [];
    let length = data.length;
    for (let i = 0; i < length; i++) {
      // strip all row with null values
      if (!data[i]['goal_id'] && !data[i]['category'] && !data[i]['name'] && !data[i]['owner_email'] && !data[i]['start_date'] && !data[i]['end_date'] && !data[i]['period'] && !data[i]['description'] && !data[i]['start_value'] && !data[i]['target_value'] && !data[i]['target_range_value'] && !data[i]['input_method'] && !data[i]['calculation_method'] && !data[i]['achievement_type'] && !data[i]['target_type'] && !data[i]['value_type'] && !data[i]['is_evidence_required'] )
      {
        // do nothing
      }
      else
      {
        data[i]['group_id'] = groupImportMa.id;
        preprocessedImport.push(data[i]);
      }
    }

    return preprocessedImport;
  }

  /**
   * Handle Upload Import Ma
   */
  const handleUploadImportMa = (data, fileInfo) => {
    console.log('original data imported: ', data);

    // cleanse
    setImportMaError([]);
    setIsImportMaSubmitButtonEnabled(false);

    let preprocessedImport = preprocessImportMaData(data);
    let preprocessedImportLength = preprocessedImport.length;

    if (preprocessedImportLength > 0) {
      setImportMaData(preprocessedImport);

      // get user token
      const userToken = localStorage.getItem('userToken');

      // define request url
      let requestUrl = `${URL_API_BACKEND}/swd/measured-activity:import?validateOnly=true`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .post(requestUrl, {
            "MeasuredActivity": preprocessedImport
          })
          .then((response) => {
            // berhasil hore
            setIsImportMaSubmitButtonEnabled(true);
          })
          .catch((error) => {
            // cleanse the content
            console.log("errors:", error);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;
              setImportMaError(error.response.data.info.errors);
              if ([ 503, 500, 403, 429, 405, 404, 401 ].includes(responseStatus) && error.response.data.hasOwnProperty("message")) {
                setErrorStatus(responseStatus);
                setOpenDialogError(true);
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  }

  /**
   * Handle submit upload import ma
   */
  const handleSubmitImportMa = () => {
    setIsImportMaSubmitButtonEnabled(false);
    setIsImportMaFormThrobberShown(true);


    // cleanse
    setImportMaError([]);
    setIsImportMaSubmitButtonEnabled(false);
    let preprocessedImportLength = importMaData.length;

    if (preprocessedImportLength > 0) {
      // get user token
      const userToken = localStorage.getItem('userToken');

      // define request url
      let requestUrl = `${URL_API_BACKEND}/swd/measured-activity:import`;
      console.log("requesting from:", requestUrl);

      axiosConfig
          .post(requestUrl, {
            "MeasuredActivity": importMaData
          })
          .then((response) => {
            // berhasil hore
            alert('data telah berhasil di import');
            handleCloseImportMaForm();
          })
          .catch((error) => {
            // cleanse the content
            console.log("errors:", error);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);
              setIsImportMaSubmitButtonEnabled(true);
              setIsImportMaFormThrobberShown(false);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                    error.response.data.info.errors !== null &&
                    error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          });
    }
  }

  /**
   * Handle open import MA form
   */
  const handleOpenImportMaForm = (item) => {
    setIsImportMaFormShown(true);
    setIsImportMaSubmitButtonEnabled(false);
    setIsImportMaFormThrobberShown(false);
    setGroupImportMa(item);
  }

  /**
   * Handle close import MA form
   */
  const handleCloseImportMaForm = () => {
    setIsImportMaFormShown(false);
    setIsImportMaSubmitButtonEnabled(false);
    setIsImportMaFormThrobberShown(false);
    setGroupImportMa({});
    setImportMaData([]);
  }

  return (
      <Container>

        <DialogError
            isOpenDialogError={isOpenDialogError}
            setOpenDialogError={setOpenDialogError}
            textErrorInformation={textErrorInformationValue}
            errorStatus={errorStatus}
            listError={listError}
        />
        <br />
        <br />
        <p>Daftar Perusahaan</p>
        <br />

        <Grid container>
          {/* Status Filter */}
          <Grid xs={12} md={3}>
            <FormControl variant="outlined">
              <Select
                  label="Status"
                  value={filterMembershipStatus}
                  onChange={handleSelectMembershipStatusFilter}
                  autoWidth
              >
                { membershipStatusListCollections.length > 0 && membershipStatusListCollections.map( (item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Status Filter */}

          <Grid md={3}>&nbsp;</Grid>
          {/* Export */}
          <Grid xs={12} md={3}>
            <Button onClick={handleExportAllButton}>Export</Button>
          </Grid>
          {/* Search Bar */}
          <Grid xs={12} md={3}>

            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                onChange={ evt => handleFilterSearch(evt.target.value) }
            />
          </Grid>
          {/* Search Bar */}
        </Grid>
        <br />
        <br />

        {/* Table Component */}
        <Grid container>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><b>Nama Group</b></TableCell>
                  <TableCell><b>Registrasi</b></TableCell>
                  <TableCell><b>Nomor Handphone</b></TableCell>
                  <TableCell><b>Status</b></TableCell>
                  <TableCell><b>Total User</b></TableCell>
                  <TableCell><b>Kuota User</b></TableCell>
                  <TableCell><b>Nama Membership</b></TableCell>
                  <TableCell><b>Level Membership</b></TableCell>
                  <TableCell><b>Update Terakhir</b></TableCell>
                  <TableCell><b>Expired</b></TableCell>
                  <TableCell><b>&nbsp;</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {/* When throbber is shown */}
                {
                  isGroupTableThrobberShown === true ? (
                      <TableRow>
                        <TableCell  align="center" colSpan={11}>
                          <h3>Fetching Data ...</h3>
                        </TableCell>
                      </TableRow>
                  ) : null
                }

                {/* When throbber is hide */}
                { isGroupTableThrobberShown === false && groupListCollections.length > 0 && groupListCollections.map((item) => (
                    <TableRow key={ "group-table-row-" + item.id} style={{ fontSize: '8pt' }}>
                      <TableCell component="th" scope="row">{ item.name !== null && item.name !== '' ? item.name : '-' }<br />{ item.superadmin_email }</TableCell>
                      <TableCell>{ item.created_at !== null && item.created_at !== '' ?  DateTimeFormatter(item.created_at) : '-' }</TableCell>
                      <TableCell>{ item.superadmin_mobile_country_number_code + '' + item.superadmin_mobile_number }</TableCell>
                      <TableCell>{ item.membershipStatus !== null && item.membershipStatus.name }</TableCell>
                      <TableCell>{ item.membership_user_has_used }</TableCell>
                      <TableCell>{ item.membership_user_count }</TableCell>
                      <TableCell>{ item.membershipName !== null && item.membershipName.name }</TableCell>
                      <TableCell>{ item.membershipLevel !== null && item.membershipLevel.name }</TableCell>
                      <TableCell>{ item.updated_at !== null && item.updated_at !== '' ? DateTimeFormatter(item.updated_at) : '-' }</TableCell>
                      <TableCell>{ item.expiry_date !== null && item.expiry_date !== '' ? DateTimeFormatter(item.expiry_date) : '-' }</TableCell>
                      <TableCell>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(evt) => handleClickActionButton(evt, item)} style={{ padding: '1px' }}>
                          <h4><b>. . .</b></h4>
                        </Button>

                        {
                          actionButtonItem !== null && actionButtonItem.id === item.id && (
                              <Menu
                                  id="simple-menu"
                                  anchorEl={actionButtonAnchorEl}
                                  keepMounted
                                  open={true}
                                  onClose={handleCloseActionButton}
                              >
                                <MenuItem onClick={() => handleReportGoalMeasuredActivity(item)}>Report Goal / MA&nbsp;</MenuItem>
                                <MenuItem onClick={() => handleOpenImportGoalMaForm(item) }>Import Goal & MA</MenuItem>
                                <MenuItem onClick={() => handleOpenImportMaForm(item) }>Import MA</MenuItem>
                                <MenuItem onClick={(evt) => handleClickEditButton(evt, item)}>Edit&nbsp;&nbsp;&nbsp;</MenuItem>
                                <MenuItem onClick={(evt) => handleOpenDeleteForm(evt, item)}>Delete&nbsp;&nbsp;&nbsp;</MenuItem>
                              </Menu>

                          )
                        }
                      </TableCell>
                    </TableRow>
                ))}

                {/* Empty State */}

                {
                  isGroupTableThrobberShown === false && groupListCollections.length === 0 ? (
                      <TableRow>
                        <TableCell  align="center" colSpan={11}>
                          <h3>No Data</h3>
                        </TableCell>
                      </TableRow>
                  ) : null
                }

              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        {/* Table Pagination */}
        <Grid container>
          <Grid item md={8}>
            &nbsp;
          </Grid>
          <Grid item md={4} xs={12}>
            <TablePagination
                rowsPerPageOptions={groupListCollectionsRowPerPageOptions}
                count={groupListCollectionsOriginalCount}
                rowsPerPage={groupListCollectionsRowPerPage}
                page={groupListCollectionsPage}
                onChangePage={handleGroupListCollectionsChangePage}
                onChangeRowsPerPage={handleGroupListCollectionsChangeRowsPerPage}
                style={{ border: 'none', float: 'right', marginTop: '4px' }}
            />
          </Grid>
        </Grid>
        <br />
        <br />

        {/* Import Form */}
        { isImportGoalMaFormShown && groupImportGoalMa && (
            <Dialog
                open={ isImportGoalMaFormShown }
                onClose={ () => handleCloseImportGoalMaForm() }
                fullScreen
            >
              <DialogTitle style={{ textAlign: 'left' }}>
                <Grid container>
                  <Grid xs={9}>
                    <h4>Import Goal Untuk Perusahaan { groupImportGoalMa.name }</h4>
                  </Grid>

                  <Grid xs={3}>
                    <p onClick={ () => handleCloseImportGoalMaForm() } style={{ textAlign: 'right' }}>
                      X
                    </p>
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent>
                <Grid container>
                  <Grid xs={12} style={{ textAlign: 'center' }}>

                    <CSVReader
                        onFileLoaded={handleUploadImportGoalMa}
                        parserOptions={{
                          header: true,
                          dynamicTyping: true,
                          skipEmptyLines: true,
                          transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
                        }}
                    />
                    {
                      isImportGoalMaFormThrobberShown && (
                          <div>
                            <CircularProgress />
                            <h1>
                              import sedang diproses (import dapat memakan waktu hingga 2 menit) ....
                            </h1>
                          </div>
                      )
                    }
                    {
                      importGoalMaData.length > 0 && !isImportGoalMaFormThrobberShown && (
                          <Fragment>

                            <TableContainer component={Paper}>
                              <Table>

                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>id</b></TableCell>
                                    <TableCell><b>goal_id</b></TableCell>
                                    <TableCell><b>category</b></TableCell>
                                    <TableCell><b>name</b></TableCell>
                                    <TableCell><b>owner_email</b></TableCell>
                                    <TableCell><b>time_frame</b></TableCell>
                                    <TableCell><b>start_date</b></TableCell>
                                    <TableCell><b>end_date</b></TableCell>
                                    <TableCell><b>period</b></TableCell>
                                    <TableCell><b>description</b></TableCell>
                                    <TableCell><b>start_value</b></TableCell>
                                    <TableCell><b>target_value</b></TableCell>
                                    <TableCell><b>target_range_value</b></TableCell>
                                    <TableCell><b>input_method</b></TableCell>
                                    <TableCell><b>calculation_method</b></TableCell>
                                    <TableCell><b>achievement_type</b></TableCell>
                                    <TableCell><b>value_type</b></TableCell>
                                    <TableCell><b>is_evidence_required</b></TableCell>
                                    <TableCell><b>weighting</b></TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>

                                  {
                                    importGoalMaData.map((item, i) => (

                                        <TableRow style={{ fontSize: '8pt' }}>

                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'id').length > 0 && importGoalMaError[i].filter(r => r.key === 'id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.id !== undefined && item.id !== null && item.id.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.id !== undefined && item.id !== null && item.id.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'goal_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'goal_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.goal_id !== undefined && item.goal_id !== null && item.goal_id }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'goal_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.goal_id !== undefined && item.goal_id !== null && item.goal_id.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'category').length > 0 && importGoalMaError[i].filter(r => r.key === 'category')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.category !== undefined && item.category !== null && item.category.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'category')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.category !== undefined && item.category !== null && item.category.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'name').length > 0 && importGoalMaError[i].filter(r => r.key === 'name')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.name !== undefined && item.name !== null && item.name.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'name')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.name !== undefined && item.name !== null && item.name.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'owner_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'owner_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.owner_email !== undefined && item.owner_email !== null && item.owner_email.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'owner_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.owner_email !== undefined && item.owner_email !== null && item.owner_email.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'time_frame_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'time_frame_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.time_frame !== undefined && item.time_frame !== null && item.time_frame.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'time_frame_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.time_frame !== undefined && item.time_frame !== null && item.time_frame.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'start_date').length > 0 && importGoalMaError[i].filter(r => r.key === 'start_date')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.start_date !== undefined && item.start_date !== null && item.start_date.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'start_date')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.start_date !== undefined && item.start_date !== null && item.start_date.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'end_date').length > 0 && importGoalMaError[i].filter(r => r.key === 'end_date')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.end_date !== undefined && item.end_date !== null && item.end_date.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'end_date')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.end_date !== undefined && item.end_date !== null && item.end_date.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'period_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'period_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.period !== undefined && item.period !== null && item.period.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'period_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.period !== undefined && item.period !== null && item.period.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'description').length > 0 && importGoalMaError[i].filter(r => r.key === 'description')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.description !== undefined && item.description !== null && item.description.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'description')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.description !== undefined && item.description !== null && item.description.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'start_value').length > 0 && importGoalMaError[i].filter(r => r.key === 'start_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.start_value !== undefined && item.start_value !== null && item.start_value.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'start_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.start_value !== undefined && item.start_value !== null && item.start_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'target_value').length > 0 && importGoalMaError[i].filter(r => r.key === 'target_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.target_value !== undefined && item.target_value !== null && item.target_value.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'target_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.target_value !== undefined &&  item.target_value !== null && item.target_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'target_range_value').length > 0 && importGoalMaError[i].filter(r => r.key === 'target_range_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.target_range_value !== undefined && item.target_range_value !== null && item.target_range_value.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'target_range_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.target_range_value !== undefined && item.target_range_value !== null && item.target_range_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'input_method_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'input_method_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.input_method !== undefined && item.input_method !== null && item.input_method.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'input_method_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.input_method !== undefined && item.input_method !== null && item.input_method.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'calculation_method_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'calculation_method_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.calculation_method !== undefined && item.calculation_method !== null && item.calculation_method.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'calculation_method_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.calculation_method !== undefined && item.calculation_method !== null && item.calculation_method.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'achievement_type_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'achievement_type_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.achievement_type !== undefined && item.achievement_type !== null && item.achievement_type.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'achievement_type_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.achievement_type !== undefined && item.achievement_type !== null && item.achievement_type.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'value_type_id').length > 0 && importGoalMaError[i].filter(r => r.key === 'value_type_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.value_type !== undefined && item.value_type !== null && item.value_type.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'value_type_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.value_type !== undefined && item.value_type !== null && item.value_type.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'is_evidence_required').length > 0 && importGoalMaError[i].filter(r => r.key === 'is_evidence_required')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.is_evidence_required !== undefined && item.is_evidence_required !== null && item.is_evidence_required.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'is_evidence_required')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.is_evidence_required !== undefined && item.is_evidence_required !== null && item.is_evidence_required.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importGoalMaError.length > 0 && importGoalMaError[i].length > 0 && importGoalMaError[i].filter(r => r.key === 'weighting').length > 0 && importGoalMaError[i].filter(r => r.key === 'weighting')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.weighting !== undefined && item.weighting !== null && item.weighting.toString() }<br/><b>({ importGoalMaError[i].filter(r => r.key === 'weighting')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.weighting !== undefined && item.weighting !== null && item.weighting.toString() }</TableCell>
                                            )
                                          }
                                        </TableRow>
                                    ))
                                  }


                                </TableBody>
                              </Table>
                            </TableContainer>





                          </Fragment>
                      )
                    }

                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>

                <Grid container>
                  <Grid xs={1}>&nbsp;</Grid>
                  <Grid xs={3}>
                    <Button
                        variant="contained"
                        onClick={ () => handleCloseImportGoalMaForm() }
                        disabled={!isCancelImportGoalMaButtonEnabled}
                        fullWidth
                    >Batal</Button>
                  </Grid>

                  <Grid xs={4}>&nbsp;</Grid>
                  <Grid xs={3}>
                    <Button
                        onClick={ () => handleSubmitImportGoalMa() }
                        variant="contained"
                        color="primary"
                        disabled={!isImportGoalMaSubmitButtonEnabled}
                        fullWidth
                    >Import
                    </Button>
                  </Grid>
                  <Grid xs={1}>&nbsp;</Grid>
                </Grid>

              </DialogActions>

            </Dialog>
        )}

        {/* Import Form MA */}
        { isImportMaFormShown && groupImportMa && (
            <Dialog
                open={ isImportMaFormShown }
                onClose={ () => handleCloseImportMaForm() }
                fullScreen
            >
              <DialogTitle style={{ textAlign: 'left' }}>
                <Grid container>
                  <Grid xs={9}>
                    <h4>Import MA Untuk Perusahaan { groupImportMa.name }</h4>
                  </Grid>

                  <Grid xs={3}>
                    <p onClick={ () => handleCloseImportMaForm() } style={{ textAlign: 'right' }}>
                      X
                    </p>
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent>
                <Grid container>
                  <Grid xs={12} style={{ textAlign: 'center' }}>

                    <CSVReader
                        onFileLoaded={handleUploadImportMa}
                        parserOptions={{
                          header: true,
                          dynamicTyping: true,
                          skipEmptyLines: true,
                          transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
                        }}
                    />
                    {
                      isImportMaFormThrobberShown && (
                          <div>
                            <CircularProgress />
                            <h1>
                              import sedang diproses (import dapat memakan waktu hingga 2 menit) ....
                            </h1>
                          </div>
                      )
                    }
                    {
                      importMaData.length > 0 && !isImportMaFormThrobberShown && (
                          <Fragment>

                            <TableContainer component={Paper}>
                              <Table>

                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>goal_id</b></TableCell>
                                    <TableCell><b>name</b></TableCell>
                                    <TableCell><b>owner_email</b></TableCell>
                                    <TableCell><b>time_frame</b></TableCell>
                                    <TableCell><b>start_date</b></TableCell>
                                    <TableCell><b>end_date</b></TableCell>
                                    <TableCell><b>period</b></TableCell>
                                    <TableCell><b>description</b></TableCell>
                                    <TableCell><b>start_value</b></TableCell>
                                    <TableCell><b>target_value</b></TableCell>
                                    <TableCell><b>target_range_value</b></TableCell>
                                    <TableCell><b>input_method</b></TableCell>
                                    <TableCell><b>calculation_method</b></TableCell>
                                    <TableCell><b>achievement_type</b></TableCell>
                                    <TableCell><b>value_type</b></TableCell>
                                    <TableCell><b>is_evidence_required</b></TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>

                                  {
                                    importMaData.map((item, i) => (

                                        <TableRow style={{ fontSize: '8pt' }}>

                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'goal_id').length > 0 && importMaError[i].filter(r => r.key === 'goal_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.goal_id !== undefined && item.goal_id !== null && item.goal_id }<br/><b>({ importMaError[i].filter(r => r.key === 'goal_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.goal_id !== undefined && item.goal_id !== null && item.goal_id.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'name').length > 0 && importMaError[i].filter(r => r.key === 'name')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.name !== undefined && item.name !== null && item.name.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'name')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.name !== undefined && item.name !== null && item.name.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'owner_id').length > 0 && importMaError[i].filter(r => r.key === 'owner_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.owner_email !== undefined && item.owner_email !== null && item.owner_email.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'owner_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.owner_email !== undefined && item.owner_email !== null && item.owner_email.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'time_frame_id').length > 0 && importMaError[i].filter(r => r.key === 'time_frame_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.time_frame !== undefined && item.time_frame !== null && item.time_frame.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'time_frame_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.time_frame !== undefined && item.time_frame !== null && item.time_frame.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'start_date').length > 0 && importMaError[i].filter(r => r.key === 'start_date')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.start_date !== undefined && item.start_date !== null && item.start_date.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'start_date')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.start_date !== undefined && item.start_date !== null && item.start_date.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'end_date').length > 0 && importMaError[i].filter(r => r.key === 'end_date')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.end_date !== undefined && item.end_date !== null && item.end_date.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'end_date')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.end_date !== undefined && item.end_date !== null && item.end_date.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'period_id').length > 0 && importMaError[i].filter(r => r.key === 'period_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.period !== undefined && item.period !== null && item.period.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'period_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.period !== undefined && item.period !== null && item.period.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'description').length > 0 && importMaError[i].filter(r => r.key === 'description')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.description !== undefined && item.description !== null && item.description.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'description')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.description !== undefined && item.description !== null && item.description.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'start_value').length > 0 && importMaError[i].filter(r => r.key === 'start_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.start_value !== undefined && item.start_value !== null && item.start_value.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'start_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.start_value !== undefined && item.start_value !== null && item.start_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'target_value').length > 0 && importMaError[i].filter(r => r.key === 'target_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.target_value !== undefined && item.target_value !== null && item.target_value.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'target_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.target_value !== undefined &&  item.target_value !== null && item.target_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'target_range_value').length > 0 && importMaError[i].filter(r => r.key === 'target_range_value')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.target_range_value !== undefined && item.target_range_value !== null && item.target_range_value.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'target_range_value')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.target_range_value !== undefined && item.target_range_value !== null && item.target_range_value.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'input_method_id').length > 0 && importMaError[i].filter(r => r.key === 'input_method_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.input_method !== undefined && item.input_method !== null && item.input_method.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'input_method_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.input_method !== undefined && item.input_method !== null && item.input_method.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'calculation_method_id').length > 0 && importMaError[i].filter(r => r.key === 'calculation_method_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.calculation_method !== undefined && item.calculation_method !== null && item.calculation_method.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'calculation_method_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.calculation_method !== undefined && item.calculation_method !== null && item.calculation_method.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'achievement_type_id').length > 0 && importMaError[i].filter(r => r.key === 'achievement_type_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.achievement_type !== undefined && item.achievement_type !== null && item.achievement_type.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'achievement_type_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.achievement_type !== undefined && item.achievement_type !== null && item.achievement_type.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'value_type_id').length > 0 && importMaError[i].filter(r => r.key === 'value_type_id')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.value_type !== undefined && item.value_type !== null && item.value_type.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'value_type_id')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.value_type !== undefined && item.value_type !== null && item.value_type.toString() }</TableCell>
                                            )
                                          }
                                          {
                                            importMaError.length > 0 && importMaError[i].length > 0 && importMaError[i].filter(r => r.key === 'is_evidence_required').length > 0 && importMaError[i].filter(r => r.key === 'is_evidence_required')[0].key !== undefined ? (
                                                <TableCell style={{ backgroundColor: 'orange' }}>{ item.is_evidence_required !== undefined && item.is_evidence_required !== null && item.is_evidence_required.toString() }<br/><b>({ importMaError[i].filter(r => r.key === 'is_evidence_required')[0].description })</b></TableCell>
                                            ) : (
                                                <TableCell>{ item.is_evidence_required !== undefined && item.is_evidence_required !== null && item.is_evidence_required.toString() }</TableCell>
                                            )
                                          }
                                        </TableRow>
                                    ))
                                  }


                                </TableBody>
                              </Table>
                            </TableContainer>





                          </Fragment>
                      )
                    }

                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>

                <Grid container>
                  <Grid xs={1}>&nbsp;</Grid>
                  <Grid xs={3}>
                    <Button
                        variant="contained"
                        onClick={ () => handleCloseImportMaForm() }
                        disabled={!isCancelImportMaButtonEnabled}
                        fullWidth
                    >Batal</Button>
                  </Grid>

                  <Grid xs={4}>&nbsp;</Grid>
                  <Grid xs={3}>
                    <Button
                        onClick={ () => handleSubmitImportMa() }
                        variant="contained"
                        color="primary"
                        disabled={!isImportMaSubmitButtonEnabled}
                        fullWidth
                    >Import
                    </Button>
                  </Grid>
                  <Grid xs={1}>&nbsp;</Grid>
                </Grid>

              </DialogActions>

            </Dialog>
        )}

        {/* Delete Form */}
        <Dialog
            open={ isDeleteFormShown }
            onClose={ (evt) => { handleCloseDeleteForm(evt) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
          <DialogTitle id="alert-dialog-title" style={ {textAlign: "left"} }>
            <Grid container>
              <Grid xs={9}>
                <Typography >
                  <h4>
                    {deleteFormTitleContent}
                  </h4>
                </Typography>
              </Grid>

              <Grid xs={3}>
                <p onClick={ (evt) => { handleCloseDeleteForm(evt) }} style={{ textAlign: 'right' }}>
                  X
                </p>
              </Grid>
            </Grid>

          </DialogTitle>

          <DialogContent>
            {
              isDeleteFormThrobberShown === true ? (
                  <Grid container>
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
              ) : (
                  <Fragment>

                    {
                      deletePercentages > 0.00 ? (
                          <Fragment>
                            <Grid container>
                              <Grid xs={12} style={{ textAlign: 'center' }}>
                                <CircularProgress variant={'static'} value={deletePercentages} size={75}/>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={12} style={{ textAlign: 'center' }}>
                                <h2>{deletePercentages}%</h2>
                              </Grid>
                            </Grid>
                          </Fragment>
                      ) : (
                          <Fragment>
                            <Grid container style={{ color: 'maroon', textAlign: 'center'}}>
                              <Grid xs={12}>
                                <WarningIcon style={{fontSize: '48pt' }}/>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={12}>
                                &nbsp;
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={12}>
                                <Fragment>{deleteFormMessageContent}</Fragment>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={12}>
                                &nbsp;
                              </Grid>
                            </Grid>
                          </Fragment>
                      )
                    }



                  </Fragment>
              )
            }

          </DialogContent>

          {/* Delete */}
          <DialogActions>
            {
              isDeleteFormThrobberShown === true ? (null) :(
                  <Grid container>
                    <Grid xs={1}>&nbsp;</Grid>
                    <Grid xs={3}>
                      {
                        deleteButtonTimerActive === true && (
                            <Button
                                fullWidth
                                disabled
                            >
                              Hapus ({deleteButtonTimer})
                            </Button>
                        )
                      }
                      {
                        deleteButtonTimerActive === false && (
                            <Button
                                onClick={ (evt) => handleClickDeleteButton(evt) }
                                disabled={!isDeleteButtonEnabled}
                                fullWidth
                            >Hapus
                            </Button>
                        )
                      }
                    </Grid>
                    <Grid xs={4}>&nbsp;</Grid>
                    <Grid xs={3}>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={ (evt) => handleCloseDeleteForm(evt) }
                          disabled={!isCancelButtonEnabled}
                          fullWidth
                      >Batal</Button>
                    </Grid>
                    <Grid xs={1}>&nbsp;</Grid>
                  </Grid>
              )
            }

          </DialogActions>
          <br />

        </Dialog>

      </Container>
  )
};

export default ViewNewDashboard;
