import React, { useEffect } from "react";
import { IndexedDBConfig } from "./constants/config-indexed-db";
import { initDB } from "react-indexed-db";

import { useRoutes, useRedirect } from "hookrouter";

import { routes } from "./routes";
import NotFoundPage from "./screen/Explore/NotFoundPage";

// import { useClearCache } from "react-clear-cache";
import preval from "preval.macro";

initDB(IndexedDBConfig);

const App = () => {
  /*
     -------------------------------
    |                                 |
    | HANDLE ROUTER WITH HOOK ROUTER  |
    |                                 |
      -------------------------------
   */

  useRedirect("/", "/home");
  const routeResult = useRoutes(routes);

  // const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache({
  //   duration: 5000,
  //   // auto: true
  // });

  // set Build Time in Cookie
  function setCookie(name, value) {
    document.cookie = `${name}=${value}`;
  }

  // get Build Time from Cookie
  function getCookie(name) {
    let cookeiName = document.cookie.split("=")[0];

    if (name === cookeiName) {
      let cookieVal = document.cookie.split("=")[1];
      return cookieVal;
    }

    return "";
  }

  // check Build Time from Cookie
  function checkCookie(buildTimeCurrent) {
    const cookieVal = getCookie("buildTime");
    const currentVal = buildTimeCurrent;

    if (cookieVal !== "") {
      if (cookieVal === currentVal) {
        console.log("Build Time SAMA");
      } else {
        setCookie("buildTime", currentVal);
        window.location.reload(true);
      }
    }
  }

  useEffect(() => {
    //*
    const elem = document.getElementById("progress");
    // Build Time
    const buildTimeCurrent = preval`module.exports = new Date().toLocaleString();`;

    if (elem) {
      elem.remove();
    }

    // Check The value of Cookie
    checkCookie(buildTimeCurrent);

    //*

    // if (isLatestVersion === true) {
    //   console.log("isLatestVersion-1 : ", isLatestVersion);
    //   console.log("V-", latestVersion);
    // } else {
    //   console.log(`Running emptyCache()`);
    //   emptyCacheStorage();
    // }

    console.log("Build Date time : ", buildTimeCurrent);
  });

  return routeResult || <NotFoundPage />;
};

export default App;
