import React, { useEffect, useState, Fragment} from "react";
import { 
  Typography, Grid, Button, TextField, Card, CardContent,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, Select, MenuItem, InputLabel, CircularProgress
} from '@material-ui/core';

//import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';
import DialogError from "../../../../components/DialogError";
//import FirstPageIcon from '@material-ui/icons/FirstPage';
//import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
//import LastPageIcon from '@material-ui/icons/LastPage';

//import axios from 'axios';
//import { navigate } from 'hookrouter';

import axiosConfig from '../../../../constants/config-axios';

import { URL_API_BACKEND } from '../../../../constants/config-api';
import DateTimeFormatter from '../../../../utilities/DateTimeFormatter';

//import PictEmptyStateDashboard from '../../../../assets/images/empty_dashboard.png';

const GroupMembershipDetail = (props) => {


  /*
      ```````````````````
      COMPONENT DID MOUNT

      ```````````````````
  */

  /**
   * Handle error dialog
   */
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = React.useState('whoops something went wrong');
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);

  /**
   * Utility to get id
   */
  const getID = () => {
    let pageURL = window.location.href;
    let id = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    return id;
  };

  /**
   *  Handle throbber
   */
  const [isGroupDetailThrobberShown, setIsGroupDetailThrobberShown] = React.useState(false);

  /**
   * Handle request GroupDetail
   */
  const [ group, setGroup ] = useState(null);
  const requestGroupDetail = () => {

    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = getID();

    if (userToken !== undefined && id !== undefined) {
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}`;
      console.log("requesting from:", requestUrl);

      axiosConfig
        .get(requestUrl)
        .then((response) => { 
          setIsGroupDetailThrobberShown(false);
            console.log("Response Original GROUP VIEW DETAIL:", response)
            
            // assign data
            let responseData = response.data.data;
            setGroup({
              'id': responseData.id,
              'name': responseData.name,
              'created_at': responseData.created_at,
              'updated_at': responseData.updated_at,
              'superadmin_email': responseData.superadmin_email,
              'superadmin_mobile_number_country_code': responseData.superadmin_mobile_number_country_code,
              'superadmin_mobile_number': responseData.superadmin_mobile_number,
              'membership_user_has_used': responseData.membership_user_has_used,
              'membership_user_count': responseData.membership_user_count,
              'expiry_date': responseData.expiry_date,
              'membershipCategory': responseData.membershipCategory,
              'membershipName': responseData.membershipName,
              'membershipLevel': responseData.membershipLevel,
              'membershipStatus': responseData.membershipStatus
              
            });
        })
        .catch((error) => {
          setIsOpenUpdateFormThrobberShown(false);
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([ 422, 400 ].includes(responseStatus)) {

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
            {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }
            }

          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    }
  };

  /**
   * Membership Update Form State
   */  
  const [openMembershipUpdateForm, setOpenMembershipUpdateForm] = React.useState(false);
  const [isOpenUpdateFormButtonEnabled, setIsOpenUpdateFormButtonEnabled] = React.useState(true);
  
  /**
   * Membership Update Form Throbber
   */
  const [isOpenUpdateFormThrobberShown, setIsOpenUpdateFormThrobberShown] = React.useState(false);

  /**
   * Handle membership level collections
   */
  const [membershipLevelCollections, setMembershipLevelCollections] = React.useState([]);
  /**
   * Handle select membership name id
   */
  const [membershipNameId, setMembershipNameId] = React.useState('');
  const [membershipNameCollections, setMembershipNameCollections] = React.useState([]);
  const [isMembershipNameIdEnabled, setIsMembershipNameIdEnabled] = React.useState(true);

  const handleSelectMembershipNameId = (evt) => {
    setMembershipNameId(evt.target.value);

    // handle below form
    setMembershipDurationId('');
    setIsMembershipDurationIdEnabled(true);
    setMembershipStatusId('');
    setIsMembershipStatusIdEnabled(false);
    setMembershipUserQuota(0);
    setIsMembershipUserQuotaEnabled(false);
    setIsUpdateButtonEnabled(false);
  }

  /**
   * Handle select membership duration
   */
  const [membershipDurationId, setMembershipDurationId] = React.useState('');
  const [membershipDurationCollections, setMembershipDurationCollections] = React.useState([]);
  const [isMembershipDurationIdEnabled, setIsMembershipDurationIdEnabled] = React.useState(false);
  const handleSelectMembershipDurationId = (evt) => {
    setMembershipDurationId(evt.target.value);

    // handle below form
    // setMembershipDurationId('');
    // setIsMembershipDurationIdEnabled(true);
    setMembershipStatusId('');
    setIsMembershipStatusIdEnabled(true);
    setMembershipUserQuota(0);
    setIsMembershipUserQuotaEnabled(false);
    setIsUpdateButtonEnabled(false);
  };

  /**
   * Handle select membership status id
   */
  const [membershipStatusId, setMembershipStatusId] = React.useState('');
  const [membershipStatusCollections, setMembershipStatusCollections] = React.useState([]);
  const [isMembershipStatusIdEnabled, setIsMembershipStatusIdEnabled] = React.useState(false);

  const handleSelectMembershipStatusId = (evt) => {
    setMembershipStatusId(evt.target.value);


    // handle below form
    // setMembershipDurationId('');
    // setIsMembershipDurationIdEnabled(true);
    // setMembershipStatusId('');
    // setIsMembershipStatusIdEnabled(true);
    setMembershipUserQuota(0);
    setIsMembershipUserQuotaEnabled(true);
    setIsUpdateButtonEnabled(false);
  }
  
  /**
   * Handle select membership user quota
   */
  const [membershipUserQuota, setMembershipUserQuota] = React.useState(0);
  // const [membershipUserQuotaCollections, setMembershipUserQuotaCollections] = React.useState([]);
  // const [membershipUserQuotaMinimum, setMembershipUserQuotaMinimum] = React.useState(0);
  // const [membershipUserQuotaMaximum, setMembershipUserQuotaMaximum] = React.useState(0);
  const [isMembershipUserQuotaEnabled, setIsMembershipUserQuotaEnabled] = React.useState(false);
  const handleInputMembershipUserQuota = (evt) => {
    setMembershipUserQuota(evt.target.value);

    // handle below form
    // setMembershipDurationId('');
    // setIsMembershipDurationIdEnabled(true);
    // setMembershipStatusId('');
    // setIsMembershipStatusIdEnabled(true);
    // setMembershipUserQuota(0);
    // setIsMembershipUserQuotaEnabled(true);
    setIsUpdateButtonEnabled(true);
  }


  /**
   * Handle button for submit
   */
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = React.useState(false);
  const [isUpdateButtonThrobberShown, setIsUpdateButtonThrobberShown] = React.useState(false);

  const requestUpdateMembership = () => {
    // show throbber
    setIsUpdateButtonThrobberShown(true); 
    setIsUpdateButtonEnabled(false);

    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = getID();

    if (userToken !== undefined && id !== undefined) {
      // sanitize request
      let requestMembershipNameId = membershipNameId;
      let requestMembershipStatusId = membershipStatusId;
      let requestMembershipUserQuota = membershipUserQuota;
      
      // define membership category
      let requestMembershipCategoryId = '';
      let requestMembershipName = membershipNameCollections.find(row => row.id === requestMembershipNameId);
      if (requestMembershipName) {
        requestMembershipCategoryId = requestMembershipName.membership_category_id; 
      }

      let requestMembershipLevelId = '';
      if (!isNaN(requestMembershipUserQuota)) {
        for (let i = 0; i < membershipLevelCollections.length; i++) {
          console.log(membershipLevelCollections[i]);
          if (membershipLevelCollections[i].user_minimum_quota <= requestMembershipUserQuota && requestMembershipUserQuota <=  membershipLevelCollections[i].user_maximum_quota ) {
            
            requestMembershipLevelId = membershipLevelCollections[i].id;
          }
        }
      } else {
        requestMembershipUserQuota = 0;
      }

      let requestMembershipDurationId = membershipDurationId;

      // define request url
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}/membership`;
      
      // define request body
      let requestBody = {
        '_method': 'put',
        'Group': {
          'membership_category_id': requestMembershipCategoryId,
          'membership_name_id': requestMembershipNameId,
          'membership_level_id': requestMembershipLevelId,
          'membership_user_quota': requestMembershipUserQuota,
          'membership_duration_id': requestMembershipDurationId,
          'membership_status_id': requestMembershipStatusId
        }
      };
      
      console.log("requesting from:", requestUrl);
      console.log("request body:", requestBody);

      axiosConfig
        .post(requestUrl, requestBody)
        .then((response) => { 
            setIsUpdateButtonThrobberShown(false);
            setOpenMembershipUpdateForm(false);
            setIsOpenUpdateFormButtonEnabled(true);
            // console.log("Response Original MEMBERSHIP UPDATE:", response);
            // forceUpdate();
            window.location.reload();
            // navigate(`/account-management/group/${id}`);
        })
        .catch((error) => {
          setIsUpdateButtonThrobberShown(false);
          setIsUpdateButtonEnabled(true);
          
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([ 422, 400 ].includes(responseStatus)) {

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
            {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }
            }
            

          } else {
            setOpenDialogError(true);
            setIsUpdateButtonEnabled(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    }
  };


  /**
   * Handle click open membership update button
   */
  const handleOpenMembershipUpdateForm = (evt) => {
    setOpenMembershipUpdateForm(true);
    setIsOpenUpdateFormThrobberShown(true);
    setIsOpenUpdateFormButtonEnabled(false);
    requestMembershipUpdateForm();
  };

  /**
   * Handle click close membership update form
   */
  const handleCloseMembershipUpdateForm = (evt) => {
    
    // set the below form to disabled or enabled
    setMembershipNameId('');
    setIsMembershipNameIdEnabled(true);
    setMembershipDurationId('');
    setIsMembershipDurationIdEnabled(false);
    setMembershipStatusId('');
    setIsMembershipStatusIdEnabled(false);
    setMembershipUserQuota(0);
    setIsMembershipUserQuotaEnabled(false);
    setIsUpdateButtonEnabled(false);

    setIsOpenUpdateFormThrobberShown(true);
    setOpenMembershipUpdateForm(false);
    setIsOpenUpdateFormButtonEnabled(true);
  };

  /**
   * Handle update membership
   */
  const handleUpdateMembership = (evt) => {
    requestUpdateMembership();
  }


  /**
   * Handle click submit membership 
   */
  
  const requestMembershipUpdateForm = () => {
    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = getID();
    setIsUpdateButtonEnabled(false);

    if (userToken !== undefined && id !== undefined) {

      // // sanitize 
      // let queryMembershipCategoryId = membershipCategoryId;

      // define request url
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}/membership/update`;

      console.log("requesting from:", requestUrl);

      axiosConfig
        .get(requestUrl)
        .then((response) => { 
          setIsOpenUpdateFormThrobberShown(false);
            console.log("Response Original MEMBERSHIP UPDATE FORM:", response)
            
            // assign data
            let responseData = response.data.data;

            setMembershipNameCollections(responseData.membershipNameCollections);
            setMembershipDurationCollections(responseData.membershipDurationCollections);
            setMembershipStatusCollections(responseData.membershipStatusCollections);
            setMembershipLevelCollections(responseData.membershipLevelCollections);
            
        })
        .catch((error) => {
          setIsOpenUpdateFormThrobberShown(false);
          // error response
          if (error.response !== undefined) {
            
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([ 422, 400 ].includes(responseStatus)) {

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
            {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }
            }

          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    }
  };
  
  /**
   * Handle membership update form
   */
  useEffect(() => {
    console.log("useEffect GroupMembershipDetail");
    requestGroupDetail();
    requestMembershipUpdateForm();
  },[]);
  
    return (
      <Fragment>
        
        <DialogError
            isOpenDialogError={isOpenDialogError}
            setOpenDialogError={setOpenDialogError}
            textErrorInformation={textErrorInformationValue}
            errorStatus={errorStatus}
            listError={listError}
        />  
        {
          isGroupDetailThrobberShown === true && (
            <Fragment>
              <br />
              <br />
              <br />
              <br />
              <CircularProgress />
            </Fragment>
          )
        }
        {
          isGroupDetailThrobberShown === false && group !== null && (
          <Fragment>
            <Grid container>
              {/* Tanggal Registrasi */}
              <Grid item xs={12} md={2}>
                <b>Tanggal Registrasi</b>
                <br />
                { group.created_at !== null && group.created_at !== '' ? DateTimeFormatter(group.created_at) : '-' }
              </Grid>
              
              {/* Superadmin Email */}
              <Grid item xs={12} md={3}>
                <b>Email</b>
                <br />
                {group.superadmin_email}
              </Grid>

              {/* Superadmin Mobile Number */}
              <Grid item xs={12} md={2}>
                <b>No Handphone</b>
                <br />
                {group.superadmin_mobile_number_country_code !== undefined ? group.superadmin_mobile_number_country_code : ''} { ' ' + group.superadmin_mobile_number}
              </Grid>

              {/* User Quota Used */}
              <Grid item xs={12} md={1}>
                <b>Total User</b>
                <br />
                {group.membership_user_has_used}
              </Grid>

              {/* Membership User Count */}
              <Grid item xs={12} md={1}>
                <b>Kuota User</b>
                <br />
                {group.membership_user_count}
              </Grid>

              {/* User Quota Remaining */}
              <Grid item xs={12} md={1}>
                <b>Sisa Kuota</b>
                <br />
                {group.membership_user_count - group.membership_user_has_used}
              </Grid>

              {/* Expiry Date */}
              <Grid item xs={12} md={1}>
                <b>Expired</b>
                <br />
                { group.expiry_date !== null && group.expiry_date !== '' ? DateTimeFormatter(group.expiry_date) : '-' }
              </Grid>
            </Grid>

            <br />
            <br />
            

            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>

            <Grid container>
              {/* Expiry Date */}
              <Grid item xs={12} md={2}>
                <b>Last Update</b>
                <br />
                { group.updated_at !== null && group.updated_at !== '' ? DateTimeFormatter(group.updated_at) : '-' }
              </Grid>
            </Grid>

            <br />
            <br />
            

            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>

            {/* Kelola membership Title */}
            <Grid container>
              <Grid item xs={12} md={3}>
                <b>Kelola Membership</b>
              </Grid>
            </Grid>
            
            <br />
            <br />

            {/* Kelola membership information */}
            <Grid container>
              {/* Membership Info */}
              <Grid item xs={12} md={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography component="p">
                      { group.membershipName !== null ? group.membershipName.name : '-' }
                    </Typography>
                    <Typography color="textSecondary">
                      { group.membershipName !== null ? group.membershipName.name : '-' } ( {group.membershipLevel !== null ? group.membershipLevel.name : '-'} )
                    </Typography>
                    <Typography variant="body2" component="p">
                      { group.membershipStatus !== null ? group.membershipStatus.name : '-' } ( {group.membershipStatus !== null ? group.membershipStatus.name : '-'} )
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            
            <br />
            <br />

            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>

            {/* Kelola membership action */}
            <Grid container>
              {/* Membership Info */}
              <Grid item xs={12} md={1}>
                { isOpenUpdateFormButtonEnabled === true ? (
                  <Button 
                    variant="contained"
                    color="primary"
                    onClick={ (evt) => { handleOpenMembershipUpdateForm(evt) } }
                    fullWidth
                  >
                    Update
                  </Button>
                ) : (

                  <Button 
                    variant="contained"
                    color="primary"
                    onClick={ (evt) => { handleOpenMembershipUpdateForm(evt) } }
                    fullWidth
                    disabled
                  >
                    Update
                  </Button>
                )}
              </Grid>
            </Grid>      
          </Fragment>
          )
        }

        <br />
        <br />

        {/* Dialog membership update */}
        {
          openMembershipUpdateForm === true && (
            <Fragment>
            <Dialog
                open={ openMembershipUpdateForm }
                onClose={ (evt) => { handleCloseMembershipUpdateForm(evt) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title" style={ {textAlign: "left"} }>
                  <Grid container>
                    <Grid xs={9}>
                      <Typography >
                        <h3>
                        Update Membership
                        </h3>
                      </Typography>
                    </Grid>

                    <Grid xs={3}>
                      <p onClick={ (evt) => { handleCloseMembershipUpdateForm(evt) }} style={{ textAlign: 'right' }}>
                        X
                      </p>
                    </Grid>
                  </Grid>

                </DialogTitle>
                  
                  {
                    isOpenUpdateFormThrobberShown === false ? (
                      
                    <DialogContent>

                      {/* membership_name_id */}
                      <Grid container>
                          <InputLabel>Nama Membership</InputLabel>
                      </Grid>
                      <Grid container>
                        <FormControl variant={ isMembershipNameIdEnabled ? 'outlined' : 'filled' }  fullWidth>
                          <Select
                            value={membershipNameId}
                            onChange={(evt) => { handleSelectMembershipNameId(evt) }}
                            disabled={!isMembershipNameIdEnabled}
                          >
                            {
                              membershipNameCollections.length > 0 && membershipNameCollections.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <br />
                      
                      {/* membership_duration_id*/}
                      <Grid container>
                          <InputLabel>Duration</InputLabel>
                      </Grid>
                      <Grid container>
                        <FormControl variant={ isMembershipDurationIdEnabled ? 'outlined' : 'filled' } fullWidth>
                          
                          <Select
                            value={membershipDurationId}
                            onChange={(evt) => { handleSelectMembershipDurationId(evt) }}
                            disabled={!isMembershipDurationIdEnabled}
                          >
                          {
                            membershipDurationCollections.length > 0 && membershipDurationCollections.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                          }
                          </Select>                          
                        </FormControl>
                      </Grid>
                      <br />

                      {/* membership_status_id */}
                      <Grid container>
                          <InputLabel>Status Membership</InputLabel>
                      </Grid>
                      <Grid container>
                        <FormControl variant={ isMembershipStatusIdEnabled ? 'outlined' : 'filled' }  fullWidth>
                          
                          <Select
                            value={membershipStatusId}
                            onChange={(evt) => { handleSelectMembershipStatusId(evt) }}
                            disabled={!isMembershipStatusIdEnabled}
                          >
                          {
                            membershipStatusCollections.length > 0 && membershipStatusCollections.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                          }
                          </Select>

                        </FormControl>
                      </Grid>
                      <br />

                      {/* membership_user_quota*/}
                      <Grid container>
                          <InputLabel>Quota User</InputLabel>
                      </Grid>
                      <Grid container>
                        <FormControl variant={ isMembershipUserQuotaEnabled ? 'outlined' : 'filled' } fullWidth>
                          <TextField
                            value={membershipUserQuota}
                            onChange={(evt) => { handleInputMembershipUserQuota(evt) }}
                            disabled={!isMembershipUserQuotaEnabled}
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <br />
                      
                      

                      {/* button update membership */}
                      <Grid container>
                      <Button 
                        variant="contained"
                        color="primary"
                        onClick={ (evt) => { handleUpdateMembership(evt) } }
                        fullWidth
                        disabled={!isUpdateButtonEnabled}
                      >
                        {
                          isUpdateButtonThrobberShown === true ? (
                            <Fragment>
                              <CircularProgress />
                            </Fragment>
                          ) : (
                            <Fragment>
                              Update Membership
                            </Fragment>
                          )
                        }
                      </Button>
                      </Grid>
                      <br />

                    </DialogContent>

                  ) : (
                    <Dialog Content>

                      <Grid container>
                        Fetching Data ...
                      </Grid>
                      <Grid container>
                      <Button 
                        variant="contained"
                        onClick={ (evt) => { handleCloseMembershipUpdateForm(evt) } }
                        fullWidth
                      >
                        Close
                      </Button>
                      </Grid>
                    </Dialog>
                  )
                }

                <DialogActions>
                   
                </DialogActions>
                <br />
            </Dialog>
            </Fragment>
          )
        }

      </Fragment>
    )
};

export default GroupMembershipDetail;
