import React, { useCallback, useEffect, useState, useContext, Fragment} from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
  Typography, Paper, Grid, Button, Switch,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, Select, Menu, MenuItem, InputLabel,
  Container, Table, TableBody, TableRow, TableCell, TableContainer, TableHead,
  FormControlLabel, TextField, TablePagination, CircularProgress
} from '@material-ui/core';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import DialogError from "../../../../components/DialogError";
import {  useDebouncedCallback } from "use-debounce";

import axiosConfig from '../../../../constants/config-axios';

import { URL_API_BACKEND } from '../../../../constants/config-api';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

// const useStyle = makeStyles(styles);

const GroupUserDetail = () => {


  /*
      ```````````````````
      COMPONENT DID MOUNT

      ```````````````````
  */
  
  /**
   * Handle error dialog
   */
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = React.useState('whoops something went wrong');
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);

  /**
   * User Collections
   */
  const [userListCollections, setUserListCollections] = React.useState([]);


  /**
   * Filter status 
   */
  const [filterStatusListCollections, setFilterStatusListCollections] = React.useState([
    
      {
        'id': '-1',
        'name': 'Semua'
      },
      {
        'id': '1',
        'name': 'Pending'
      }, 
      {
        'id': '3',
        'name': 'Enabled'
      },
      {
        'id': '4',
        'name': 'Disabled'
      }
    
  ]);
  const [filterStatus, setFilterStatus] = React.useState('-1');
  const handleSelectStatusFilter = (evt) => {
    resetPagingPage();
    // show throbber
    setIsUserTableThrobberShown(true);
    setFilterStatus(evt.target.value);
  };

  /**
   * Filter search
   */
  const [filterSearch, setFilterSearch] = React.useState('');
  const [ handleFilterSearch ] = useDebouncedCallback(
    // to memoize debouncedFunction we use useCallback hook.
    // In this case all linters work correctly
    React.useCallback(value => {
      if (value) {
        resetPagingPage();
        setFilterSearch(value);
        // show throbber
        setIsUserTableThrobberShown(true);
      } else {
        resetPagingPage();
        setFilterSearch('');
        // show throbber
        setIsUserTableThrobberShown(true);
      }
    }, []), 1000,

    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 5000 }
  );

  /**
   * Paging
   */
  const [userListCollectionsRowPerPageOptions] = React.useState([5, 10 ]);
  const [userListCollectionsRowPerPage, setUserListCollectionsRowPerPage] = React.useState(10);
  const [userListCollectionsPage, setUserListCollectionsPage] = React.useState(0);
  const [userListCollectionsOriginalCount, setUserListCollectionsOriginalCount] = React.useState(0);

  const handleUserListCollectionsChangePage = (evt, newPage) => {
    // show throbber
    setIsUserTableThrobberShown(true);
    setUserListCollectionsPage(newPage);
  };

  const handleUserListCollectionsChangeRowsPerPage = (evt) => {
    // show throbber
    setIsUserTableThrobberShown(true);
    setUserListCollectionsRowPerPage(parseInt(evt.target.value, 10));
    setUserListCollectionsPage(0);
  };
  
  const resetPagingPage = () => {
    setUserListCollectionsPage(0);
  };
  
  /**
   *  Handle throbber
   */
  const [isUserTableThrobberShown, setIsUserTableThrobberShown] = React.useState(true);


  /**
   * Handle user to enable
   * 
   * @param {User} item 
   */
  const handleSaveToEnable = (evt, item) => {
    
    setUserEnableDisableSwitchThrobber(item.id);

    // get user id
    let id = item.id;

    // get credential token
    let userToken = localStorage.getItem('userToken');

    // set selected filter status
    if (userToken !== undefined) {

      let requestUrl = `${URL_API_BACKEND}/account-management/user/${id}:enable`;
      console.log("requesting from:", requestUrl);

      axiosConfig
        .post(requestUrl)
        .then((response) => {
          console.log("Response Original Save User To Enable : ", response);
          setUserEnableDisableSwitchState({ ...userEnableDisableSwitchState, [item.id]: true });
          setIsUserTableThrobberShown(false);
          setUserEnableDisableSwitchThrobber('');

        })
        .catch((error) => {
          setUserEnableDisableSwitchThrobber('');
          console.log("Error : ", error.response);
          
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([ 422, 400 ].includes(responseStatus)) {

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
            {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }
            }
            

          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
          setIsUserTableThrobberShown(false);

        });
    }
  };

  /**
   * Handle user to disable
   * 
   * @param {User} item 
   */
  const handleSaveToDisable = (evt, item) => {
    setUserEnableDisableSwitchThrobber(item.id);

    // get user id
    let id = item.id;

    // get credential token
    let userToken = localStorage.getItem('userToken');

    // set selected filter status
    if (userToken !== undefined) {

      let requestUrl = `${URL_API_BACKEND}/account-management/user/${id}:disable`;
      console.log("requesting from:", requestUrl);

      axiosConfig
        .post(requestUrl, [])
        .then((response) => {
          console.log("Response Original Save User To Disable : ", response);
          setUserEnableDisableSwitchState({ ...userEnableDisableSwitchState, [item.id]: false });
          setUserEnableDisableSwitchThrobber('');
        })
        .catch((error) => {
          if (error !== undefined) {
            console.log(error);
            setUserEnableDisableSwitchThrobber('');
            console.log("Error : ", error.response);
            
            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([ 422, 400 ].includes(responseStatus)) {

                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
              {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
                }
              }
              

            } else {
              setOpenDialogError(true);
              setTextErrorInformationValue("Whoops, something went wrong !");
            }
          }
        });

    }
  }

  /**
   * Handle state status collections
   */
  const [userEnableDisableSwitchState, setUserEnableDisableSwitchState] = React.useState({});
  const [userEnableDisableSwitchThrobber, setUserEnableDisableSwitchThrobber] = React.useState('');

  useEffect(() => {
    // get id
    window.scrollTo(0, 0);
    const pathname = window.location.pathname.split("/");
    const id = pathname[ pathname.length - 2];
    // get credential token
    const userToken = localStorage.getItem('userToken');

    // set selected filter status
    if (userToken !== undefined) {
      // sanitize filter
      let queryFilterStatus = filterStatus;
      if (queryFilterStatus === '-1') {
        queryFilterStatus = '';
      }
      let queryFilterSearch = filterSearch;

      // sanitize paging
      let queryUserListCollectionsRowPerPage = userListCollectionsRowPerPage;
      let queryUserListCollectionPagingOffset = userListCollectionsPage * userListCollectionsRowPerPage;

      let requestUrl = `${URL_API_BACKEND}/account-management/user?options[filter][group_id]=${id}&options[filter][status_id]=${queryFilterStatus}&options[filter][search]=${queryFilterSearch}&options[paging][limit]=${queryUserListCollectionsRowPerPage}&options[paging][offset]=${queryUserListCollectionPagingOffset}`;
      console.log("requesting from:", requestUrl);

      axiosConfig
        .get(requestUrl)
        .then((response) => {
            
            console.log("Response Original USER LIST COLLECTIONS : ", response)
            if (response.data.data !== null) {
              let responseData = response.data.data;
              let responseInfo = response.data.info;

              let userEnableDisableSwitchStateOriginal = {};

              for (let i = 0; i < responseData.length; i++) {

                if (responseData[i].status.code === 'user_status_id_enum_enabled') {
                  userEnableDisableSwitchStateOriginal[ responseData[i].id ] = true;
                } else {
                  userEnableDisableSwitchStateOriginal[ responseData[i].id ] = false;
                }

              }

              setUserEnableDisableSwitchState(userEnableDisableSwitchStateOriginal);
              setUserListCollections(responseData)
              setUserListCollectionsOriginalCount(responseInfo.count);
            } else {
              setUserListCollectionsOriginalCount(0);
            }
        })
        .catch((error) => {
          setUserListCollectionsOriginalCount(0);
          console.log("Error : ", error.response);
          
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([ 422, 400 ].includes(responseStatus)) {

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([ 503, 500, 403, 429, 405, 404, 401].includes(responseStatus))
            {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue( `Error ${responseStatus} : ` +  error.response.data.message);
              }
            }
            

          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        })
      
      
      setIsUserTableThrobberShown(false);
    }
       
  },[filterStatus, filterSearch, userListCollectionsPage, userListCollectionsRowPerPage])


  return (
      <Fragment>

        {/* Komponen group detail */}
        <Grid item xs={12}>
          <br />
          <br />
          <p>Daftar User</p>
          <br />
          
        <DialogError
            isOpenDialogError={isOpenDialogError}
            setOpenDialogError={setOpenDialogError}
            textErrorInformation={textErrorInformationValue}
            errorStatus={errorStatus}
            listError={listError}
        />  

          <Grid container>
            {/* Status Filter */}
            <Grid xs={12} md={3}>
              <FormControl variant="outlined">
                  <Select
                    label="Status"
                    value={filterStatus}
                    onChange={handleSelectStatusFilter}
                    autoWidth
                  >
                    { filterStatusListCollections.length > 0 && filterStatusListCollections.map( (item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Grid>
              {/* Status Filter */}

            <Grid md={6}>&nbsp;</Grid>

            {/* Search Bar */}
            <Grid xs={12} md={3}>
              
              <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onChange={ evt => handleFilterSearch(evt.target.value) }
              />
            </Grid>
            {/* Search Bar */}
          </Grid>
          <br />
          <br />
                  
          {/* Table Component */}
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><b>Name</b></TableCell>
                  <TableCell><b>Role</b></TableCell>
                  <TableCell style={{ align: 'center' }}><b>Confirmed</b></TableCell>
                  <TableCell style={{ align: 'center' }}><b>Status</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
              {/* When throbber is shown */}
              {
                isUserTableThrobberShown === true ? (
                  <TableRow>
                    <TableCell  align="center" colSpan={11}>
                      <h3>Fetching Data ...</h3>
                    </TableCell>
                  </TableRow>
                ) : null
              }
              
              {/* When throbber is shown */}
              {
                isUserTableThrobberShown === false && userListCollections.length === 0 ? (
                  <TableRow>
                    <TableCell  align="center" colSpan={11}>
                      <h3>No Data</h3>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {/* When throbber is hide */}
              { isUserTableThrobberShown === false && userListCollections.length > 0 && userListCollections.map((item) => (
                <TableRow key={ "user-table-row-" + item.id}>
                  <TableCell component="th" scope="row">
                    <div style={{ color: 'DarkBlue' }}> { item.email !== '' ? item.email : '-' }</div>
                    <br />
                    { item.member !== null ? item.member.first_name + ' ' + item.member.last_name : '-' }
                  </TableCell>
                  <TableCell  style={{ textTransform: 'capitalize' }}>
                    { item.role.length > 0 ? item.role[0].name : '-' }
                  </TableCell>
                  <TableCell style={{ align: 'center' }}>
                    { item.status !== null && (item.status.code === 'user_status_id_enum_enabled' || item.status.code === 'user_status_id_enum_disabled') ? (<VerifiedUserIcon style={{ color: 'lightGreen' }}/>) : (<HighlightOffIcon style={{ color: 'red' }} />) }
                  </TableCell>
                  <TableCell>
                    {
                      item.id === userEnableDisableSwitchThrobber ? (
                        <Fragment>
                          <CircularProgress />
                        </Fragment>
                      ) : (
                        <Fragment>
                          { item.status === null && (<div>-</div>)}
                          { item.status !== null && item.status.code === 'user_status_id_enum_pending' && (
                            
                            <FormControlLabel
                              control={<IOSSwitch checked={false}/>}
                              disabled={true}
                            />
                          )}
                          {
                            item.status !== null && item.status.code !== 'user_status_id_enum_pending' && !userEnableDisableSwitchState[item.id] && (
          
                              <FormControlLabel
                                control={<IOSSwitch checked={ userEnableDisableSwitchState[item.id] } name={item.id} onClick={(evt) => handleSaveToEnable(evt, item)}/>}
                              />
                            )
                          }
                          {
                            item.status !== null && item.status.code !== 'user_status_id_enum_pending' && userEnableDisableSwitchState[item.id] && (
          
                              <FormControlLabel
                                control={<IOSSwitch checked={ userEnableDisableSwitchState[item.id] } name={item.id} onClick={(evt) =>  handleSaveToDisable(evt, item)} />}
                              />
                            )
                          }
                        </Fragment>
                      )
                    }
                    
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
            </Grid>
          </Grid>

          {/* Table Pagination */}
          <Grid container>
            <Grid item md={7}>
              &nbsp;
            </Grid>
            <Grid item md={5} xs={12}>
              <TablePagination
                  rowsPerPageOptions={userListCollectionsRowPerPageOptions}
                  count={userListCollectionsOriginalCount}
                  rowsPerPage={userListCollectionsRowPerPage}
                  page={userListCollectionsPage}
                  onChangePage={handleUserListCollectionsChangePage}
                  onChangeRowsPerPage={handleUserListCollectionsChangeRowsPerPage}
                  style={{ border: 'none', marginTop: '4px' }}
                />
            </Grid>
          </Grid>
          <br />
          <br />
        </Grid>
        

        <br />
        <br />

      

      </Fragment>
    )
};

export default GroupUserDetail;
