function DateTimeFormatter (src) {
    
    let date = new Date(src);

    let tahun = date.getFullYear();
    let bulan = date.getMonth();
    let tanggal = date.getDate();
    let hari = date.getDay();
    let jam = date.getHours();
    let menit = date.getMinutes();
    let detik = date.getSeconds();

    switch (hari) {
        case 0: hari = "Minggu"; break;
        case 1: hari = "Senin"; break;
        case 2: hari = "Selasa"; break;
        case 3: hari = "Rabu"; break;
        case 4: hari = "Kamis"; break;
        case 5: hari = "Jum'at"; break;
        case 6: hari = "Sabtu"; break;
    } 

    switch (jam) {
        case 0: jam = "00"; break;
        case 1: jam = "01"; break;
        case 2: jam = "02"; break;
        case 3: jam = "03"; break;
        case 4: jam = "04"; break;
        case 5: jam = "05"; break;
        case 6: jam = "06"; break;
        case 7: jam = "07"; break;
        case 8: jam = "08"; break;
        case 9: jam = "09"; break;
    }
    
    switch(bulan) {
        case 0: bulan = "Januari"; break;
        case 1: bulan = "Februari"; break;
        case 2: bulan = "Maret"; break;
        case 3: bulan = "April"; break;
        case 4: bulan = "Mei"; break;
        case 5: bulan = "Juni"; break;
        case 6: bulan = "Juli"; break;
        case 7: bulan = "Agustus"; break;
        case 8: bulan = "September"; break;
        case 9: bulan = "Oktober"; break;
        case 10: bulan = "November"; break;
        case 11: bulan = "Desember"; break;
    }
    let outp = tanggal + ' ' + bulan + ' ' + tahun  + ' ' + jam + ':' + menit + ':' + detik;

    return outp;
};

export default DateTimeFormatter;