import React, { useEffect, Fragment} from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import {  Grid, Hidden } from '@material-ui/core';

import GroupSidebar from './Components/GroupSidebar';
import GroupMembershipDetail from './Components/GroupMembershipDetail';
import GroupUserDetail from './Components/GroupUserDetail';
import DialogError from "../../../components/DialogError";

import { useRoutes, navigate } from 'hookrouter';

import isAuthenticated from '../../../utilities/IsAuthenticated';
import axiosConfig from '../../../constants/config-axios';

import { URL_API_BACKEND } from '../../../constants/config-api';


const theme = createMuiTheme({
    
    palette: {

        primary: {
            main: '#cc0707', //#cc0707, #c62828
            light: '#ff5f52',
            dark: '#8e0000',
            contrastText: '#ffffff'
        }
    }       
});

const routeSidebarGroupDetail = { 
    '/': () => (

      <Fragment>
        <GroupMembershipDetail />
      </Fragment>
    ),
    '/user': () => (
      <Fragment>
        <GroupUserDetail />
      </Fragment>
    ),
};

const ViewGroupDetail = (props) => {

  const routeResultSidebarGroupDetail = useRoutes(routeSidebarGroupDetail);

  /**
   * Handle error dialog
   */
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = React.useState('whoops something went wrong');
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);

  /**
   * Handle request GroupDetail
   */
  const [ group, setGroup ] = React.useState(null);
  const requestGroupDetail = () => {

    // get credential token
    const userToken = localStorage.getItem('userToken');
    let id = props.id

    if (userToken !== undefined && id !== undefined) {
      let requestUrl = `${URL_API_BACKEND}/account-management/group/${id}`;
      console.log("requesting from:", requestUrl);

      axiosConfig
        .get(requestUrl)
        .then((response) => { 
            console.log("Response Original GROUP VIEW DETAIL:", response)
            
            // assign data
            let responseData = response.data.data;
            setGroup({
              'id': responseData.id,
              'name': responseData.name,
              'created_at': responseData.created_at,
              'superadmin_email': responseData.superadmin_email,
              'superadmin_mobile_number_country_code': responseData.superadmin_mobile_number_country_code,
              'superadmin_mobile_number': responseData.superadmin_mobile_number,
              'used_user_quota': responseData.used_user_quota,
              'membership_user_quota': responseData.membership_user_quota,
              'user_quota_remaning': responseData.user_quota_remaning,
              'expiry_date': responseData.expiry_date,
              'membershipCategory': responseData.membershipCategory,
              'membershipName': responseData.membershipName,
              'membershipLevel': responseData.membershipLevel,
              'membershipStatus': responseData.membershipStatus
            });
        })
        .catch((error) => {
          // error response
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue(
                  "Error 500 : " +  error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
        });
    }
  };

  /*
      ```````````````````
      COMPONENT DID MOUNT

      ```````````````````
  */
  useEffect(() => {
    // get id
    console.log("props", props);
    window.scrollTo(0, 0);
    const id = props.id;
    console.log("Group ID:", id);

    // set side bar configuration
    setListItemMenu([
      {
        'id': 'sidebar-list-item-menu-membership',
        'label': 'Membership',
        'navigate': '/account-management/group/' + id
      },
      {
        'id': 'sidebar-list-item-menu-user',
        'label': 'User',
        'navigate': '/account-management/group/' + id + '/user'
      }
    ]);

    // determine selected sidebar
    let currentLocation = window.location.href;
    if (currentLocation.indexOf('/account-management/group/' + id + '/user') !== -1)
    {
      setSidebarOpenMenuType('sidebar-list-item-menu-user');
    }
    else
    {
      setSidebarOpenMenuType('sidebar-list-item-menu-membership');
    }

    // redirect to login page when not authenticated
    console.log("ViewGroupDetail check isAuthenticated");

    isAuthenticated();
    requestGroupDetail();

  },[])


  /**
   * Set sidebar list item menu
   */
  const [ listItemMenu, setListItemMenu ] = React.useState([]);

  /**
   * Handle sidebar open menu
   */
  const [sidebarOpenMenuType, setSidebarOpenMenuType] = React.useState('');

  return (
    <Fragment>
      <Grid container>

        {/* Komponen Sidebar */}
      
          <Grid item md={2} xs={12}>
            <GroupSidebar 
              listItemMenu={listItemMenu}
              sidebarOpenMenuType={sidebarOpenMenuType}
              setSidebarOpenMenuType={setSidebarOpenMenuType}
            />

            <Hidden smDown>
              <div style={{ backgroundColor: '#edeef0', fontSize: '12pt', paddingLeft: '10px', minHeight: '500px' }}>
                &nbsp;
              </div>
            </Hidden>
          </Grid>
        
        {/* Span */}
        <Grid item xs={1}>&nbsp;</Grid>

        <Grid item xs={9}>
          
        <DialogError
            isOpenDialogError={isOpenDialogError}
            setOpenDialogError={setOpenDialogError}
            textErrorInformation={textErrorInformationValue}
            errorStatus={errorStatus}
            listError={listError}
        />  

          <Grid container>
            <Grid item xs={12}>
              { group !== null ? (
                <h2>{ group.name }</h2>
              ) : (
                "-"
              ) }
            </Grid>
          </Grid>

          <Grid container>
            { routeResultSidebarGroupDetail || "Page " + window.location.href + " Not Found" }
          </Grid>
        </Grid>
          
        <br />
        <br />
      </Grid>

    </Fragment>
  )
};

export default ViewGroupDetail;
