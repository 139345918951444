import React, { Component, useEffect } from 'react';
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Typography, Hidden, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { navigate } from 'hookrouter';

import logo from '../assets/images/performate.png';



// import { NavLink } from "react-router-dom";
// import logo from '../assets/images/iconAB128.png';
// import logoText from '../assets/images/logoText.png';
// import logoText from '../assets/images/performate.png';

const theme = createMuiTheme({

    palette: {
        primary: {
            main: '#cc0707', //#cc0707, #c62828
            light: '#ff5f52',
            dark: '#8e0000',
            contrastText: '#ffffff'
        }
    },
    overrides: {

       MuiToolbar : {

        root:{
            backgroundColor: 'white'
          }
        },
        MuiTypography: {
            root: {
                // textTransform: 'lowercase'
                
            },
            // button: {
            //     textTransform: 'lowercase',
            //     color: 'red'
            // }
        }
    }

  });


// const useStyles = makeStyles(theme => ({
const styles = theme => ({

    root: {
      flexGrow: 1,
    },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    //   color: 'grey',
      
    // },
    title: {
      flexGrow: 1,
      color: 'grey',
      paddingBottom: theme.spacing(0),
      marginTop: theme.spacing(1)
    },
    question: {
        color: 'grey',
        textTransform : 'capitalize',
        fontFamily: 'Nunito'
        
    },
    buttonAction: {
        textTransform : 'capitalize',
        fontFamily: 'Nunito'

    },
    logoText: {
        flexGrow: 1,
        width: 120,
        height: 24
    },
    logo: {
        width: 30,
        height: 30
    }
  });

  const useStyles = makeStyles(styles);

const Header = props => {

    const classes = useStyles();
    // const { classes} = props;

    // useEffect(() => {
        
    // }, [])
    const currentUrl = window.location.pathname;

    return (
        
      <MuiThemeProvider theme={theme}>
        <AppBar position="sticky">
          <Toolbar>     
            <Typography 
              onClick={() => navigate('/home')}
              className={classes.title}
              style={{cursor: 'pointer'}}
            >
              <h3> Web Admin</h3>
            </Typography>

            
            <Button 
                color="primary"
                variant="contained"
                className={classes.buttonAction}
                onClick={ () => { navigate('/auth/destroy') }}
            >
                Keluar
            </Button>                    
          </Toolbar>
      </AppBar>
      </MuiThemeProvider>
    )
};

export default Header;
// export default withStyles(styles)(Header);