export const IndexedDBConfig = {
    name: 'Attachment',
    version: 1,
    objectStoresMeta: [
      {
        store: 'video',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            // { 
            //     name: 'name', 
            //     keypath: 'name', 
            //     options: { unique: false } 
            // },
            // { 
            //     name: 'email', 
            //     keypath: 'email', 
            //     options: { unique: false } 
            // }
            { 
                name: 'img', 
                keypath: 'img', 
                options: { unique: false } 
            },
            { 
                name: 'title', 
                keypath: 'title', 
                options: { unique: false } 
            }
        ]
      }
    ]
  };