import React from 'react';
import { navigate } from 'hookrouter';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axiosConfig from '../../../constants/config-axios';
import { URL_API_BACKEND } from '../../../constants/config-api';
import DialogError from "../../../components/DialogError";

const LoginForm = props => {
  /**
   * Handle button state
   */
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = React.useState(false);
  
  /**
   * Handle username
   */
  const [username, setUsername] = React.useState('');
  const handleInputUsername = (evt) => {
    if (evt.target.value) {
      // set to enabled when username value is not empty
      setIsSubmitButtonEnabled(true);
    } else {
      // set to disabled when username value is empty
      setIsSubmitButtonEnabled(false)
    }
    setUsername(evt.target.value)
  };

  /**
   * Handle error dialog
   */
  const [isOpenDialogError, setOpenDialogError] = React.useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = React.useState('whoops something went wrong');
  const [errorStatus, setErrorStatus] = React.useState(undefined);
  const [listError, setListError] = React.useState([]);
  
  React.useEffect(() => {
    localStorage.clear();
  }, []);
  

  /**
   * Handle authenticate
   */
  const handleAuthenticate = () => {
   

    // define body
    let body = {
      'Auth':  {
        'username': username
      }
    };

    // set throbber to shown
    setIsThrobberShown(true);
    // disable button
    setIsSubmitButtonEnabled(false);

    // hit api
    axiosConfig
      .post(URL_API_BACKEND + `/auth/authenticate`, body)
      .then((response) => {
        console.log("Response Original Authenticate: ", response);

        if (response.status === 200) {          
          // set token  
          localStorage.setItem('userToken', username);

          // redirect to dashboard
          navigate('/dashboard');
        }
      })
      .catch(function (error) {
        // logging
        console.log("Response Original Authenticate Error:", error);
        
        // error response
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setErrorStatus(400);

            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if (error.response.status === 500) {
            setErrorStatus(500);
            setOpenDialogError(true);
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorInformationValue(
                "Error 500 : " +  error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorInformationValue("Whoops, something went wrong !");
        }
        
        setIsThrobberShown(false);
        setIsSubmitButtonEnabled(true);
      });
  }

  /**
   * Handle throbber
   */
  const [isThrobberShown, setIsThrobberShown] = React.useState(false);

	return ( 
		<div>
		
			<section style={{ paddingTop : '0px', textAlign : 'center', color: 'white' }}>
				<br />
				<br />
				<br />
				<br />
        <h3>
          Performate Web Admin
        </h3>
				<br />
			</section>
			
			<section>
        <div className="box-tour" style={{ margin : '0 auto' }}>

          {/* Error Dialog */}
          
          <DialogError
            isOpenDialogError={isOpenDialogError}
            setOpenDialogError={setOpenDialogError}
            textErrorInformation={textErrorInformationValue}
            errorStatus={errorStatus}
            listError={listError}
          />

          {/* Label Login */}
          <Grid container>
            <Grid item xs={12} style={{ textAlign : 'center' }}>
              <h3>Masuk</h3>
            </Grid>
          </Grid>

          {/* Username Field */}
          <Grid container>
            <TextField
                label="Username"
                variant="outlined"
                fullWidth
                onChange={ handleInputUsername }
            />
          </Grid>

          <br />

          {/* Login Button */}
          {
            isSubmitButtonEnabled === true ? (
              <Button 
                variant="contained"
                color="primary"
                onClick={ () => { handleAuthenticate() } }
                fullWidth
              >
                {
                  isThrobberShown === true ? (
                    <div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>Masuk</div>
                  )
                }
              </Button>
            ) : (
              <Button 
                variant="contained"
                color="primary"
                fullWidth
                disabled
              >
              {
                isThrobberShown === true ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>Masuk</div>
                )
              }
            </Button>
            )
          }
              
          {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                
                <Button 
                  onClick={() => navigate('/home/demo-schedule')}
                  variant="outlined"
                  color="primary"
                  disableElevation
                  className={classes.textLowerCase}
                >Login
                </Button>
              </Paper>
            </Grid>
  </Grid> */ }

			
				</div>
      </section>
      
			<section style={{paddingTop : '0px', textAlign : 'center' }}>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</section>
			
		</div>
	);
	
}

export default LoginForm;