import { URL_API_BACKEND } from '../constants/config-api';
import { navigate } from 'hookrouter';
import axios from 'axios';

const IsAuthenticated = () => {
  console.log("User token:", localStorage.getItem('userToken'));
  
  let header = {
    'Accept': "application/json",
    'Content-Type' : "application/json",
    'Authorization' : localStorage.getItem('userToken'),
  };

  axios.defaults.headers.common = header;    
  axios
      .get(`${URL_API_BACKEND}/auth/authenticate:check`)
      .then((response) => {
          console.log("Response is authenticated", response)                                  
      })
      .catch((error) => {
        let responseStatus = error.response.status;
        console.log("IsAuthenticated catch error response original:", error);

        if (responseStatus === 401 || responseStatus === 404) {
          alert("anda belum login atau session telah habis, silakan login ulang");
        } else if (responseStatus === 403) {
          alert("whoops, forbidden");
        } else if (responseStatus === 400) {
          alert("whoops, bad request");
        } else if (responseStatus === 503) {
          alert("whoops, server sedang maintenance");
        } else if (responseStatus === 500) {
          alert("whoops, internal server error");
        } else {
          alert("whoops, something went wrong");
        }

        navigate('/home');
      })

};

export default IsAuthenticated;