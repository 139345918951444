import React from 'react';
import { navigate } from 'hookrouter';

const AuthDestroy = () => {
 
  React.useEffect(() => {
    // set local storage to clear
    localStorage.clear();
    navigate('/home');
  }, []);
  
	return ( 
		<div>
		</div>
	);
}

export default AuthDestroy;