import React from 'react';

import LoginForm from './LoginForm';


import './Style/authenticatedForm.css';

const ViewLandingPage = (props) => {
    
    return (
        <div className="container" style={{ backgroundColor: '#1a0761' }}>
            <LoginForm />
        </div>
    )
};

export default ViewLandingPage