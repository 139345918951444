import React from 'react';


const NotFounfPage = () => {

    return (

        <h1>Page Not found</h1>
    )
};

export default NotFounfPage;