import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import preval from "preval.macro";

import { version } from "../../package.json";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";


const DialogHelp = (props) => {
  const { isOpenDialogHelp, setOpenDialogHelp } = props;
  const [buildTime, setbuildTime] = useState();

  useEffect(() => {
    setbuildTime(preval`module.exports = new Date().toLocaleString();`);
  }, []);

  return (
    <Dialog
      open={isOpenDialogHelp}
      onClose={() => setOpenDialogHelp(false)}
      aria-labelledby="alert-dialog-title-422"
      aria-describedby="alert-dialog-description-422"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title-422" style={{ textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start">
          <IconButton size="small" onClick={() => setOpenDialogHelp(false)}>
            <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="text">
          <Typography variant="h6" >
            <b>Kami senang jika bisa membantu Anda :)</b>
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogContent style={{ textAlign: "left" }}>
        <Paper
          elevation={1}
          style={{ backgroundColor: "transparant", padding: 16 }}>
          <Box display="flex" flexDirection="row">
            <Typography
              variant="subtitle1"
              style={{ color: "grey" }}>
              Kirim pertanyaan, keluhan, atau usulan Anda ke email support kami
              ke
              <b>
                <i style={{ color: "red" }}>
                  <a
                    href="mailto:admin@performate.id"
                    style={{ color: "red", textDecoration: "none" }}>
                    &nbsp;admin@performate.id
                  </a>
                </i>
              </b>
              . Pesan Anda akan kami balas maksimal dalam waktu 24 jam{" "}
              <i>(Office Hour)</i>
            </Typography>
          </Box>
        </Paper>
        <Typography variant="subtitle2" align="right" style={{ marginTop: 10 }}>
          Anda sedang menggunakan performate (web/app)
          <div>
            Versi: <strong>{version}</strong>
          </div>
          <div>
            Build time: <strong>{buildTime}</strong>
          </div>
        </Typography>
      </DialogContent>

      <br />
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => setOpenDialogHelp(false)}
          color="primary"
          size="small"
        >
          <span style={{ fontSize: 12 }}>Tutup</span>
        </Button>
      </DialogActions>

      <br />
      <br />
    </Dialog>
  );
};

export default DialogHelp;
