import React, { Fragment } from "react";

/**
 * Auth
 */
import ViewLoginPage from "./screen/Auth/AuthenticatedForm/ViewLoginPage";
/**
 * Account Management
 */
import Header from "./layout/Header";
import ViewGroupDetail from './screen/AM/Group/ViewGroupDetail';
import AuthDestroy from './screen/Auth/AuthDestroy';
import ViewNewDashboard from './screen/AM/Dashboard/ViewNewDashboard';

export const routes = {

  /**
   * Real Use In Web admin
   */

  "/home": () => (
    <Fragment>
      <ViewLoginPage />
    </Fragment>
  ),

  "/auth/destroy": () => (
    <Fragment>
      <Header />
      <AuthDestroy />
    </Fragment>
  ),

  "/dashboard": () => (
    <Fragment>
      <Header />
      <ViewNewDashboard />
    </Fragment>
  ),

  // view group detail
  "/account-management/group/:id*": ({ id }) => (
    <Fragment>
      <Header />
      <ViewGroupDetail id={id}/>
    </Fragment>
  ),

};
